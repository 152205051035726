<template>
  <div class="reset-request-container">
    <transition name="appear" mode="out-in">
      <div v-if="requestSent" data-external="reset-success-div">
        <FriedH2 class="branding title">{{ $t('password-request-sent') }}</FriedH2>
        <FriedParagraph class="description">
          {{ $t('password-request-sent-description') }}
        </FriedParagraph>
        <PaperPlane />
        <FriedButton
          data-external="reset-request-login-button"
          class="reset-button"
          @click="handleLoginClick"
        >
          {{ $t('login') }}
        </FriedButton>
      </div>
      <div v-else class="reset-request">
        <FriedH2 class="branding title">{{ $t('forgot-password') }}</FriedH2>
        <FriedParagraph class="description">
          {{ $t('forgot-password-description') }}
        </FriedParagraph>
        <FriedMessage
          v-if="error"
          data-external="reset-request-error-div"
          class="error"
          :type="MessageType.Error"
          :title="$t(error.title)"
        >
          <i18n-t :keypath="error.description">
            <FriedLink
              :normal="true"
              place="contact-support-link"
              href="http://help.getaccept.com/"
              target="_blank"
            >
              {{ $t('contact-support') }}
            </FriedLink>
          </i18n-t>
        </FriedMessage>
        <form @submit.prevent="handleSubmit">
          <FriedInput
            v-model.trim="email"
            data-external="reset-request-email-input"
            class="reset-input"
            :error-message="emailError"
            :type="InputType.Text"
            autofocus
            autocomplete="email"
            :label="$t('email')"
          />
          <FriedButton
            :loading="loading"
            data-external="reset-request-submit-button"
            type="submit"
            class="reset-button"
          >
            {{ $t('send-reset-link') }}
          </FriedButton>
        </form>
        <FriedButton
          data-external="reset-request-back-link"
          class="reset-button back"
          :button-type="ButtonType.Secondary"
          @click="handleLoginClick"
        >
          <template #leftIcon>
            <FriedIcon icon="arrow-left" />
          </template>
          {{ $t('back-to-login') }}
        </FriedButton>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import type { Ref } from 'vue';
import { watch, defineComponent, ref } from 'vue';
import { sanitizeEmail, validateEmail } from '@getaccept/lib-shared/src/helpers';
import { RecaptchaAction } from '@getaccept/lib-shared/src/recaptcha/recaptcha-action';
import type { AxiosError } from 'axios';

import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { MessageType, ButtonType, InputType } from '@getaccept/fried-tofu';
import bugsnagClient from '@getaccept/lib-shared/src/bugsnag';
import { ResetRequestError } from '../types/enums/reset-request-error';
import type { ErrorMessage } from '../../types/error-message';
import { RecaptchaService } from '../../api/recaptcha/recaptcha.service';
import { LoginService } from '../../api/login/login.service';
import type { RequestResponse } from '../../api/login/types/request-response';
import { getErrorMessage } from '../../helpers/error-message';
import { ErrorKey } from '../../types/enums/error-key';
import PaperPlane from './PaperPlane.vue';

export default defineComponent({
  components: {
    PaperPlane,
  },
  setup() {
    const router = useRouter();
    const email = ref('');
    const emailError = ref('');
    const error: Ref<ErrorMessage | null> = ref(null);
    const loading = ref(false);
    const requestSent = ref(false);
    const { t } = useI18n();

    watch(email, () => {
      emailError.value = '';
    });

    const resetRequest = async (email: string) => {
      loading.value = true;
      try {
        const recaptchaToken: string = await RecaptchaService.getToken(RecaptchaAction.Request);
        const data: RequestResponse = await LoginService.request({ email, recaptchaToken });
        if (data.status === 0) {
          resetRequestFailed({ data });
          loading.value = false;
          return;
        }
        resetRequestSuccess();
        loading.value = false;
      } catch (error) {
        resetRequestFailed({ requestError: error });
        loading.value = false;
      }
    };

    const resetRequestFailed = ({
      data,
      requestError,
    }: {
      data?: RequestResponse;
      requestError?: AxiosError;
    }) => {
      const errorMessage: string = data?.message;
      let newError: ErrorMessage;
      switch (errorMessage) {
        case ResetRequestError.Throttled:
          newError = getErrorMessage(ErrorKey.Throttled);
          break;
        case ResetRequestError.ErrorSendingRequest:
        default:
          newError = getErrorMessage(ErrorKey.Unknown);
          if (bugsnagClient) {
            if (requestError) {
              bugsnagClient.notify(requestError, event => {
                event.groupingHash = JSON.stringify(requestError);
              });
            } else {
              bugsnagClient.notify(new Error(JSON.stringify(data)), event => {
                event.groupingHash = JSON.stringify(data);
              });
            }
          }
      }
      error.value = newError;
    };
    const resetRequestSuccess = () => {
      requestSent.value = true;
    };

    const validateEmailAddress = (email: string): string => {
      if (!email) {
        return t('email-is-required');
      } else if (!validateEmail(email)) {
        return t('enter-a-valid-email');
      }
      return '';
    };

    const handleSubmit = () => {
      const cleanEmail = sanitizeEmail(email.value);
      emailError.value = validateEmailAddress(cleanEmail);
      if (emailError.value) {
        return;
      }
      resetRequest(cleanEmail);
    };

    const handleLoginClick = () => {
      router.push({ name: 'login' });
    };

    return {
      email,
      emailError,
      error,
      loading,
      requestSent,
      handleSubmit,
      handleLoginClick,
      resetRequest,
      validateEmailAddress,
      MessageType,
      ButtonType,
      InputType,
    };
  },
});
</script>

<style lang="scss" scoped>
.reset-input {
  width: 100%;
  margin-bottom: var(--spacing-100);
}

.title {
  margin-top: var(--spacing-50);
  margin-bottom: var(--spacing-100);
}

.description {
  color: var(--gray-39);
  padding-bottom: var(--spacing-150);
}

.link-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-200);
}

.error {
  margin-bottom: var(--spacing-50);
}

.reset-button {
  width: 100%;

  &.back {
    margin-top: var(--spacing-50);
  }
}
</style>
